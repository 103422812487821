import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./ReservationPage.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Alert, Autocomplete, Box, ButtonGroup, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import OtpInput from "react-otp-input";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import useVenue from "../hooks/useAllVenues";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import WineBarIcon from "@mui/icons-material/WineBar";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Call, Email, LocationOn, People, Person } from "@mui/icons-material";
import { CalendarIcon, ClockIcon, DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  ADMIN_ID,
  ADMIN_NAME,
  countries,
  CUSTOMER_GROUP,
  sendOtpViaWhatsApp,
} from "../constants/constants";
import { GetUserService } from "../services/GetUserService";
import CreateBookingService from "../services/CreateBookingService";
import pendingReview from "../assets/images/pendingReview.png";
import ReCAPTCHA from "react-google-recaptcha";
import {
  StyledButton,
  StyledInput,
  convertTo24HourFormat,
  formatDate,
  theme,
} from "../components/utils";
import { sendOtpViaChannel, verifyOtp } from "../services/OtpServices";
import sendEmailWithOTP from "../services/EmailService";

function ReservationPage() {
  const originList = [
    "facebook",
    "instagram",
    "whatsapp",
    "website",
    "twitter",
    "link",
  ];
  const { loading2, error2, venue } = useVenue();
  const params = useParams();
  const origin = params.source || "default";
  const [currentHover, setCurrentHover] = useState("");
  const [user, setUser] = useState(null);
  const [count, setCount] = useState(1);
  const [stage, setStage] = useState(0);
  const [slot, setSlot] = useState(null);
  const [date, setDate] = useState(dayjs(new Date()));
  const [seat, setSeat] = useState(null);
  const [area, setArea] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [capcha, setCapcha] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+20");
  const [isAllowedToSendOTP, setIsAllowedToSendOTP] = useState(true);
  const [remainingTime, setRemainingTime] = useState(60);

  const captchaRef = useRef();

  const [alertt, setAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const [checkCode, setCheckCode] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const locationName = venue ? venue.name : ""; // Replace with the location name you want to search for
  const encodedLocationName = encodeURIComponent(locationName);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    slot: "",
    otp: "",
    pax: "",
    seat: "",
    area: "",
    operating: "",
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [generatedOtp, setGeneratedOtp] = useState("");

  // console.log({ venue });

  useEffect(() => {
    let timeoutId;

    if (!isAllowedToSendOTP) {
      timeoutId = setTimeout(() => {
        if (remainingTime > 0) {
          setRemainingTime(remainingTime - 1);
        } else {
          setIsAllowedToSendOTP(true);
          setRemainingTime(60);
        }
      }, 1000); // Update timer every second
    }

    return () => clearTimeout(timeoutId); // Clear timer on component unmount
  }, [isAllowedToSendOTP, remainingTime]);

  const renderErrorAlert = (errorMessage, severity) => {
    return errorMessage ? (
      <Alert
        severity={severity}
        onClose={() => {
          setAlert(false);
        }}
      >
        {errorMessage}
      </Alert>
    ) : null;
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  const validateStage1 = () => {
    const newErrors = {
      date: "",
      slot: "",
      name: "",
      email: "",
      otp: "",
      pax: "",
      phone: "",
      seat: "",
      area: "",
    };
    let isValid = true;
    if (!date) {
      newErrors.date = "Kindly choose a date for your reservation";
      isValid = false;
    }

    setErrors(newErrors);
    if (!isValid) setAlert(true);
    return isValid;
  };
  const validateStage2 = () => {
    const newErrors = {
      date: "",
      slot: "",
      name: "",
      otp: "",
      pax: "",
      email: "",
      phone: "",
      seat: "",
      area: "",
    };
    let isValid = true;

    if (!slot) {
      newErrors.slot = "Kindly choose a time slot for your reservation";
      isValid = false;
    }
    setErrors(newErrors);
    if (!isValid) setAlert(true);
    return isValid;
  };

  const validateStage3 = () => {
    const newErrors = {
      date: "",
      slot: "",
      name: "",
      otp: "",
      pax: "",
      email: "",
      phone: "",
      seat: "",
      area: "",
    };
    let isValid = true;

    // if (!seat) {
    //   newErrors.seat = "Kindly choose a seat type for your reservation";
    //   isValid = false;
    // }

    if (!venue.operating) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        operating: "Kindly note that the venue is currently non-operating.",
      }));
      setAlert(true);
      return;
    }

    if (!area) {
      newErrors.area = "Kindly choose an area for your reservation";
      isValid = false;
    }
    setErrors(newErrors);
    if (!isValid) setAlert(true);
    return isValid;
  };
  // useEffect(()=>{
  //   setStage(5)
  //   setOtpSuccess(true)
  // },[])
  const validateForm = () => {
    const newErrors = {
      date: "",
      slot: "",
      name: "",
      otp: "",
      pax: "",
      email: "",
      phone: "",
      seat: "",
      area: "",
      operating: "",
    };

    let isValid = true;

    if (!venue.operating) {
      newErrors.date = "Kindly note that the venue is currently non-operating.";
      isValid = false;
    }

    if (!date) {
      newErrors.date = "Kindly choose a date for your reservation";
      isValid = false;
    }

    if (!slot) {
      newErrors.slot = "Kindly choose a time slot for your reservation";
      isValid = false;
    }
    if (!name || name.trim() === "") {
      newErrors.name = "Please enter your name";
      isValid = false;
    }

    // Email validation using a simple regex pattern
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailPattern.test(email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    // Phone number validation using a simple regex pattern
    if (!phoneCode || phoneCode.trim() === "") {
      newErrors.phone = "Please enter your country code";
      isValid = false;
    }
    const PhonePattern = /^[0-9]+$/;

    if (!phone || phone.trim() === "") {
      newErrors.phone = "Please enter your phone number";
      isValid = false;
    }
    if (!PhonePattern.test(phone)) {
      newErrors.phone = "Please enter a valid phone number";
      isValid = false;
    }

    setErrors(newErrors);
    if (!isValid) setAlert(true);
    return isValid;
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (validateForm()) {
      let userData = null;
      if (phone)
        userData = await GetUserService(
          phone.slice(0, 1) === "0" ? phone : "0" + phone
        );

      setUser(userData[0]);
      setOtpVisible(true);
      handleSendCode("");
      // handleWhatsAppSendCode("");
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    setCount(Math.max(Number(event.target.value), 1));
  };

  const createBooking = async (bookingData) => {
    try {
      setLoading(true);
      const results = await CreateBookingService(bookingData);
      setLoading(false);
      return results;
    } catch (error) {
      setError(error);
    }
  };
  const validatePax = () => {
    if (!count || (seat === "Bar" && count > 2) || count > 14) {
      setErrors((prev) => {
        return {
          ...prev,
          pax: seat
            ? `Sorry, the maximum number of allowed guests for ${seat} seat is ${
                seat === "Bar" ? 2 : 14
              } guests.`
            : `Sorry, the maximum number of allowed guests is 14 guests.`,
        };
      });
      setAlert(true);
      return false;
    }
    return true;
  };
  const handleContinue = async () => {
    if (!venue.operating) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        operating: "Kindly note that the venue is currently non-operating.",
      }));
      setAlert(true);
      return;
    }
    if (stage === 0) {
      setStage(1);
    } else if (stage === 1 && validateStage2()) {
      setStage(2);
    } else if (stage === 2 && validateStage2()) {
      if (validatePax() && validateStage3()) {
        setStage(3);
      }
    } else if (stage === 3) {
      try {
        setLoading(true);
        if (otpVisible) {
          if (phoneCode !== "+20") {
            if (otp !== generatedOtp) {
              setErrors((prev) => {
                return {
                  ...prev,
                  otp: "OTP wrong, please try again.",
                };
              });
              setAlert(true);
              setOtpSuccess(false);
            } else {
              setOtpVisible(false);
              setStage(4);
              setOtpSuccess(true);
            }
          } else {
            let verify;
            if (
              otp &&
              checkCode &&
              checkCode.trim() !== "" &&
              otp.trim() !== ""
            )
              verify = await verifyOtp(otp, checkCode);
            if (verify) {
              const otpStatus = verify.data.otpPasscodeStatus;
              if (otpStatus === 0) {
                setErrors((prev) => {
                  return {
                    ...prev,
                    otp: "OTP Expired, please try again with the new OTP.",
                  };
                });
                setAlert(true);
                setOtpSuccess(false);
              } else if (otpStatus === 2) {
                setErrors((prev) => {
                  return {
                    ...prev,
                    otp: "OTP wrong, please try again.",
                  };
                });
                setAlert(true);
                setOtpSuccess(false);
              } else if (otpStatus === 1) {
                setOtpVisible(false);
                setStage(4);
                setOtpSuccess(true);
              }
            } else {
              handleSendCode("");
              // handleWhatsAppSendCode("");
              setOtpVisible(true);
            }
          }
        } else {
          console.log("Error, Try again");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        if (error.response && error.response.data.replyCode === 2001) {
          alert("You have exceeded maximum number of trials.");
        }
      }
      setLoading(false);
    } else if (stage === 4) {
      const statusID = "eba17df3-bc23-4c6e-ba49-06aa8aab769b"; // we need to list the reservation status and add the id dynamically.
      const selectedTimeSlot = venue.timeSlotsObj.find(
        (item) => item.friendlyName === slot
      );
      let requireDeposit = false;
      if (selectedTimeSlot.events) {
        requireDeposit = selectedTimeSlot.events.includes(formatDate(date));
      }
      const depositValue = venue.eventDeposit ? venue.eventDeposit : "1000";

      if (validateForm()) {
        const bookingData = {
          accompaniedCount: count,
          channel: origin,
          comments: [area],
          conceptID: venue.id,
          createdAt: new Date().toLocaleString(),
          email: email ? email : "",
          createdByID: ADMIN_ID,
          createdByName: ADMIN_NAME,
          customerGroup: CUSTOMER_GROUP,
          customerName: user ? user.name : name,
          customerPhone: user
            ? user.phone_number
            : phone.slice(0, 1) === "0"
            ? phone
            : "0" + phone,
          date: formatDate(date),
          depositStatus: requireDeposit,
          depositValue: depositValue,
          eventGuests: [],
          mainGuest: user ? user.id : null,
          statusID: statusID, // we need to list the reservation status and add the id dynamically.
          tableName: "",
          tables: [],
          timeSlots: [selectedTimeSlot.id],
          capcha: recaptchaValue ? true : false,
          capchaToken: capcha && recaptchaValue ? recaptchaValue : null,
        };
        if (user) {
          delete bookingData.email;
        }

        try {
          const result = await createBooking(bookingData);

          setRecaptchaValue(null);
          if (captchaRef && captchaRef.current) {
            captchaRef.current.reset();
          }

          if (result.status === 200) {
            if (result.data === "capcha") {
              setCapcha(true);
            } else {
              setSuccess(true);
              setStage(5);
            }
          }
        } catch (error) {
          setError(error);
        }
      }
    }
  };
  const isMobile = window.innerWidth < 786;
  const isTablet = window.innerWidth < 1024 && window.innerWidth > 785;

  const handleSendCode = async (source) => {
    if (!sendCodeLoading) {
      // const lastRequest = await localStorage.getItem("otpSend");
      // const timeNow = new Date().getTime() + "";

      try {
        // if (parseInt(timeNow) - parseInt(lastRequest) < 60 * 1000) {
        //   alert("Kindly wait 60 seconds before sending another request");
        //   return;
        // }

        setSendCodeLoading(true);

        if (phoneCode !== "+20") {
          const otpCode = await sendEmailWithOTP({ userEmail: email });
          setGeneratedOtp(otpCode);
        } else {
          // await localStorage.setItem("otpSend", new Date().getTime() + "");
          const response = await sendOtpViaChannel(
            phoneCode +
              (phoneCode === "+20" && phone.slice(0, 1) === "0"
                ? phone.slice(1, phone.length)
                : phone)
          );
          if (response.checkCode) setCheckCode(response.checkCode);
        }

        if (source === "resend") setIsAllowedToSendOTP(false);
      } catch (error) {
        console.error("Error sending OTP:", error);
      }
      setSendCodeLoading(false);
    }
  };
  // console.log(venue.id);

  const handleWhatsAppSendCode = async (source) => {
    if (!sendCodeLoading) {
      const lastRequest = await localStorage.getItem("otpSend");
      const timeNow = new Date().getTime() + "";

      try {
        // if (parseInt(timeNow) - parseInt(lastRequest) < 60 * 1000) {
        //   alert("Kindly wait 60 seconds before sending another request");
        //   return;
        // }

        setSendCodeLoading(true);

        // await localStorage.setItem("otpSend", new Date().getTime() + "");
        const response = await sendOtpViaWhatsApp(
          phoneCode +
            (phoneCode === "+20" && phone.slice(0, 1) === "0"
              ? phone.slice(1, phone.length)
              : phone)
        );
        if (response.checkCode) setCheckCode(response.checkCode);
        if (source === "resend") setIsAllowedToSendOTP(false);
      } catch (error) {
        console.error("Error sending OTP:", error);
      }
      setSendCodeLoading(false);
    }
  };

  // const ListItemComponent = (state, setState, item) => {
  //   return (
  //     <ListItem sx={{ width: "50%" }}>
  //       <ListItemButton
  //         onClick={() => {
  //           if (state === item) {
  //             setState(null);
  //           } else {
  //             setState(item);
  //           }
  //         }}
  //         onMouseEnter={() => setCurrentHover(item)}
  //         onMouseLeave={() => setCurrentHover("")}
  //         component="a"
  //         sx={{
  //           border: "1px solid #E0BF9E",
  //           backgroundColor: state === item ? "#E0BF9E" : "unset",
  //           "&:hover": {
  //             backgroundColor: "#E0BF9E",
  //           },
  //           transition: "0.5s",
  //           borderRadius: 2,
  //         }}
  //       >
  //         <ListItemText
  //           primary={item}
  //           sx={{
  //             color:
  //               state === item || currentHover === item
  //                 ? "#4B4B4D"
  //                 : "primary.main",
  //             textAlign: "center",
  //             "&:hover": {
  //               color: "#4B4B4D",
  //             },
  //           }}
  //         />
  //       </ListItemButton>
  //     </ListItem>
  //   );
  // };

  const ListItemComponent = (state, setState, item, width) => {
    if (!item) return null;

    return (
      <ListItem sx={{ width }}>
        <ListItemButton
          onClick={() => {
            if (state === item) {
              setState(null);
            } else {
              setState(item);
            }
          }}
          onMouseEnter={() => setCurrentHover(item)}
          onMouseLeave={() => setCurrentHover("")}
          component="a"
          sx={{
            border: "1px solid #E0BF9E",
            backgroundColor: state === item ? "#E0BF9E" : "unset",
            "&:hover": {
              backgroundColor: "#E0BF9E",
            },
            transition: "0.5s",
            borderRadius: 2,
          }}
        >
          <ListItemText
            primary={item}
            sx={{
              color:
                state === item || currentHover === item
                  ? "#4B4B4D"
                  : "primary.main",
              textAlign: "center",
              "&:hover": {
                color: "#4B4B4D",
              },
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const stagesComponent = () => {
    return (
      <>
        <CalendarMonthIcon
          size="30px"
          style={{
            color: date ? "#E0BF9E" : "#fff",
            cursor: "pointer",
          }}
          onClick={() => {
            setStage(0);
            setAlert(false);
          }}
        />
        <AccessTimeFilledIcon
          size="30px"
          style={{ color: slot ? "#E0BF9E" : "#fff", cursor: "pointer" }}
          onClick={() => {
            if (validateStage1()) {
              setStage(1);
              setAlert(false);
            }
          }}
        />
        <WineBarIcon
          size="30px"
          style={{
            color: count && area ? "#E0BF9E" : "#fff",
            cursor: "pointer",
          }}
          onClick={() => {
            if (validateStage2() && validateStage1()) {
              setStage(2);
              setAlert(false);
            }
          }}
        />
        <Person
          size="30px"
          style={{
            color: name && email && phone ? "#E0BF9E" : "#fff",
            cursor: "pointer",
          }}
          onClick={() => {
            if (
              validatePax() &&
              validateStage2() &&
              validateStage1() &&
              validateStage3()
            ) {
              setStage(3);
              setAlert(false);
              setErrors({});
            }
          }}
        />

        <CheckCircleOutlineIcon
          size="30px"
          style={{
            color:
              count &&
              slot &&
              date &&
              name &&
              email &&
              phone &&
              otpSuccess &&
              errors.email === ""
                ? "#E0BF9E"
                : "#fff",
            cursor: "pointer",
          }}
          onClick={() =>
            validateStage3() &&
            validateStage2() &&
            validateStage1() &&
            validateForm() &&
            validatePax() &&
            otpSuccess
              ? setStage(4)
              : {}
          }
        />
      </>
    );
  };
  return error || error2 ? (
    <ThemeProvider theme={theme}>
      <div
        className="ReservationPage"
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            color: "#E0BF9E",
            textAlign: "center",
            zIndex: 100,
          }}
          variant="h6"
          className="typography"
        >
          <span style={{ color: "red" }}>Un expected error happened</span>
          <br></br>Sorry for any inconvenience.
        </Typography>
        <Button
          variant="contained"
          onClick={() => window.location.reload()}
          style={{ marginTop: "1rem" }}
        >
          retry
        </Button>
      </div>
    </ThemeProvider>
  ) : loading2 || !venue ? (
    <div
      className="ReservationPage"
      style={{ alignItems: "center", justifyContent: "center" }}
    >
      <ThemeProvider theme={theme}>
        <CircularProgress color="primary" />
      </ThemeProvider>
    </div>
  ) : !originList.includes(origin.toLowerCase()) ? (
    <></>
  ) : (
    <ThemeProvider theme={theme}>
      <div className="ReservationPage">
        {venue.album.map((item, index) => {
          return (
            <img
              alt="venue"
              src={item}
              key={index}
              className="Reservation_VenueImg"
              style={{
                opacity: stage % venue.album.length === index % 5 ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
              }}
            />
          );
        })}

        <div className="overlay"></div>
        <div
          className="ReservationPage_Container"
          style={{
            justifyContent:
              success || isMobile || isTablet ? "center" : "space-evenly",
          }}
        >
          <img
            alt="logo"
            src={venue.logo}
            style={{
              transform:
                venue.name === "Reif Marassi" || venue.name.includes("Megumi")
                  ? "scale(2) translateY(-1rem)"
                  : "unset",
            }}
            className="Reservation_Logo"
          />
          {success && (
            <>
              <img
                alt="pending review icon"
                src={pendingReview}
                style={{
                  width: "80px",
                  height: "80px",
                  margin: "1rem",
                  zIndex: 100,
                }}
              />

              <Typography
                sx={{
                  fontSize: 20,
                  color: "#E0BF9E",
                  textAlign: "center",
                  zIndex: 100,
                  width: isMobile ? "80%" : isTablet ? "50%" : "40%",
                  fontWeight: "600",
                  margin: 1,
                }}
                variant="h6"
                className="typography"
              >
                Tentative booking
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  color: "#fff",
                  textAlign: "center",
                  zIndex: 100,
                  width: isMobile ? "80%" : "40%",
                  fontWeight: "400",
                  margin: 1,
                }}
                variant="h6"
                className="typography"
              >
                Thank you for your reservation!
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "#fff",
                  textAlign: "center",
                  zIndex: 100,
                  width: isMobile ? "80%" : isTablet ? "50%" : "40%",
                  fontWeight: "300",
                  margin: 1,
                }}
                variant="h6"
                className="typography"
              >
                {
                  "Our reservation team is finding you a table, \n  you will receive an sms once confirmed."
                }
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: "300",
                  color: "#fff",
                  textAlign: "center",
                  zIndex: 100,
                  justifySelf: "flex-end",
                }}
                variant="h6"
                className="typography"
              >
                <LocationOn
                  sx={{ transform: "translateY(0.4rem)", color: "#E0BF9E" }}
                ></LocationOn>
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#fff", textDecoration: "none" }}
                  href={
                    venue.longitude && venue.latitude
                      ? `https://www.google.com/maps?q=${venue.longitude},${venue.latitude}`
                      : `https://www.google.com/maps/search/?api=1&query=${encodedLocationName}`
                  }
                >
                  {venue.location}
                </a>
              </Typography>
            </>
          )}
          {!success && <div className="stages">{stagesComponent()}</div>}

          {stage === 0 && (
            <>
              <Typography
                sx={{
                  fontSize: 16,
                  color: "#ccc",
                }}
                variant="h6"
                className="typography"
              >
                Kindly choose your reservation date:
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  orientation="landscape"
                  onAccept={(value) => {
                    setStage(stage + 1);
                    setDate(value);
                  }}
                  onChange={(value, context) => {
                    setDate(value);
                  }}
                  minDate={dayjs(formatDate(new Date()))}
                  defaultValue={date}
                  className="picker"
                  backgroundColor="#fff"
                  sx={{ backgroundColor: "#fff" }}
                />
              </LocalizationProvider>
            </>
          )}
          {stage === 1 && (
            <>
              <Typography
                sx={{
                  fontSize: 16,
                  color: "#ccc",
                }}
                variant="h6"
                className="typography"
              >
                Kindly pick a time slot:
              </Typography>
              <div className="listContainer">
                <List>
                  {venue.timeSlotsObj
                    .sort((slot1, slot2) => {
                      const time1 = slot1.name.split(" - ")[0];
                      const time2 = slot2.name.split(" - ")[0];
                      const convertedTime1 = convertTo24HourFormat(time1);
                      const convertedTime2 = convertTo24HourFormat(time2);

                      return convertedTime1.localeCompare(convertedTime2);
                    })
                    .map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemButton
                            onClick={() => {
                              setSlot(item.friendlyName);
                            }}
                            onMouseEnter={() =>
                              setCurrentHover(item.friendlyName)
                            }
                            onMouseLeave={() => setCurrentHover("")}
                            component="a"
                            sx={{
                              borderRadius: "5px",
                              border: "1px solid #E0BF9E",
                              backgroundColor:
                                slot === item.friendlyName
                                  ? "#E0BF9E"
                                  : "unset",
                              "&:hover": {
                                backgroundColor: "#E0BF9E",
                              },
                              transition: "0.5s",
                            }}
                          >
                            <ListItemText
                              primary={item.friendlyName}
                              secondary={item.name}
                              secondaryTypographyProps={{
                                color:
                                  slot === item.friendlyName ||
                                  currentHover === item.friendlyName
                                    ? "#4B4B4D"
                                    : "#fff",
                              }}
                              sx={{
                                color:
                                  slot === item.friendlyName ||
                                  currentHover === item.friendlyName
                                    ? "#4B4B4D"
                                    : "primary.main",
                                "&:hover": {
                                  color: "#4B4B4D",
                                },
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                </List>
              </div>
            </>
          )}
          {stage === 2 && (
            <>
              <div className="listContainer">
                {/* <Typography
                  sx={{
                    fontSize: 18,
                    color: "#ccc",
                    textAlign: "center",
                    zIndex: 10,
                  }}
                  variant="h6"
                  className="typography"
                >
                  Kindly choose a seat type:
                </Typography>
                <List sx={{ display: "flex", flexDirection: "row" }}>
                  {ListItemComponent(seat, setSeat, "Bar")}
                  {ListItemComponent(seat, setSeat, "Table")}
                </List> */}

                <Typography
                  sx={{
                    fontSize: 18,
                    color: "#ccc",
                    textAlign: "center",
                  }}
                  variant="h6"
                  className="typography"
                >
                  Which area do you prefer?
                </Typography>
                {/* <List sx={{ display: "flex", flexDirection: "row" }}>
                  {ListItemComponent(area, setArea, "Indoor")}
                  {ListItemComponent(area, setArea, "Outdoor")}
                </List> */}
                <List sx={{ display: "flex", flexDirection: "row" }}>
                  {venue.areas &&
                    venue.areas
                      .filter(
                        (area) =>
                          area !== null &&
                          area !== undefined &&
                          area.trim() !== ""
                      ) // Filter out null, undefined, and empty items
                      .map((areaType) =>
                        ListItemComponent(
                          area,
                          setArea,
                          areaType,
                          `${
                            100 /
                            venue.areas.filter(
                              (area) =>
                                area !== null &&
                                area !== undefined &&
                                area.trim() !== ""
                            ).length
                          }%`
                        )
                      )}
                </List>

                <Typography
                  sx={{
                    fontSize: 18,
                    color: "#ccc",
                    textAlign: "center",
                  }}
                  variant="h6"
                  className="typography"
                >
                  How many guests are coming?
                </Typography>

                <div className="buttonGroupDiv">
                  <ButtonGroup sx={{}}>
                    <StyledButton
                      onClick={() => setCount((prev) => prev - 1)}
                      disabled={count === 1}
                    >
                      <RemoveIcon fontSize="small" />
                    </StyledButton>
                    <StyledInput
                      size="small"
                      onChange={handleChange}
                      value={count}
                    />
                    <StyledButton onClick={() => setCount((prev) => prev + 1)}>
                      <AddIcon fontSize="small" />
                    </StyledButton>
                  </ButtonGroup>
                </div>
              </div>
            </>
          )}
          {stage === 3 && (
            <div className="listContainer">
              {otpVisible ? (
                <>
                  {phoneCode !== "+20" ? (
                    <Typography
                      sx={{
                        fontSize: 16,
                        color: "#ccc",
                        textAlign: "center",
                        marginBottom: "1rem",
                        fontWeight: 400,
                        zIndex: 10,
                      }}
                      variant="h6"
                      className="typography"
                    >
                      Kindly enter the verification code (OTP) <br></br> sent to{" "}
                      {email}{" "}
                      check the "junk folder"
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: 16,
                        color: "#ccc",
                        textAlign: "center",
                        marginBottom: "1rem",
                        fontWeight: 400,
                        zIndex: 10,
                      }}
                      variant="h6"
                      className="typography"
                    >
                      Kindly enter the verification code (OTP) <br></br> sent to{" "}
                      {phoneCode +
                        (phoneCode === "+20" && phone.slice(0, 1) === "0"
                          ? phone.slice(1, phone.length)
                          : phone)}{" "}
                      check the "junk folder"
                    </Typography>
                  )}
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    containerStyle={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => (
                      <input {...props} className="input" inputMode="numeric" />
                    )}
                  />

                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "#aaa",
                      textAlign: "center",
                      marginTop: "1rem",

                      fontWeight: 400,
                      zIndex: 10,
                    }}
                    variant="h6"
                  >
                    Didn't recieve a code ?{"  "}
                    {isAllowedToSendOTP ? (
                      <>
                        <span
                          style={{
                            color: "#E0BF9E",
                            cursor: sendCodeLoading ? "default" : "pointer",
                          }}
                          onClick={() => handleSendCode("resend")}
                        >
                          send again.{" "}
                          {sendCodeLoading && (
                            <CircularProgress
                              size={10}
                              color="primary"
                            ></CircularProgress>
                          )}
                        </span>
                        {venue?.id ===
                          "558d1f2a-ad8f-46c5-aeac-6e84ee762283" && (
                          <span
                            style={{
                              color: "#E0BF9E",
                              cursor: sendCodeLoading ? "default" : "pointer",
                              marginLeft: "2rem",
                            }}
                            onClick={() => handleWhatsAppSendCode("resend")}
                          >
                            {"    "}- or send again using WhatsApp.{" "}
                            {sendCodeLoading && (
                              <CircularProgress
                                size={10}
                                color="primary"
                              ></CircularProgress>
                            )}
                          </span>
                        )}
                      </>
                    ) : (
                      <span style={{ color: "#E0BF9E" }}>
                        resend in {remainingTime} seconds...
                      </span>
                    )}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: 16,
                      color: "#ccc",
                      textAlign: "center",
                      marginBottom: "1rem",
                    }}
                    variant="h6"
                    className="typography"
                  >
                    Kindly enter the required details below:
                  </Typography>
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      defaultValue={name}
                      required
                      onChange={(event) => setName(event.target.value)}
                      error={!!errors.name}
                      helperText={errors.name}
                      sx={{
                        input: { color: "white" },
                        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            color: "#fff",
                          },
                        "& .MuiInputLabel-root": { color: "white" },
                        "& .MuiFilledInput-input": {
                          border: "1px solid white",
                          borderRadius: 1,
                        },
                        width: "100%",
                        marginBottom: "1rem",
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      defaultValue={email}
                      required
                      onChange={(event) => setEmail(event.target.value)}
                      error={!!errors.email}
                      helperText={errors.email}
                      sx={{
                        input: { color: "white" },
                        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            color: "#fff",
                          },
                        "& .MuiInputLabel-root": { color: "white" },
                        "& .MuiFilledInput-input": {
                          border: "1px solid white",
                          borderRadius: 1,
                        },
                        width: "100%",
                        marginBottom: "1rem",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "25%" }}
                        options={countries}
                        autoHighlight
                        disableClearable
                        onChange={(event, newValue) => {
                          setPhoneCode(newValue.phone);
                        }}
                        getOptionLabel={(option) => option.phone}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            ({option.code}) {option.phone}
                          </Box>
                        )}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label={phoneCode}
                              id="outlined-basic"
                              variant="outlined"
                              onChange={(event) =>
                                setPhoneCode(event.target.value.phone)
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              sx={{
                                input: { color: "white" },

                                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                                  {
                                    color: "#fff",
                                  },
                                "& .MuiInputLabel-root": { color: "white" },
                                "& .MuiFilledInput-input": {
                                  border: "1px solid white",
                                  borderRadius: 1,
                                },
                                "& .MuiInputLabel-input": { color: "#fff" },
                              }}
                            />
                          );
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Phone Number"
                        variant="outlined"
                        defaultValue={phone}
                        required
                        onChange={(event) => setPhone(event.target.value)}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        sx={{
                          input: { color: "white" },

                          "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              color: "#fff",
                            },
                          "& .MuiInputLabel-root": { color: "#fff" },
                          width: "72%",
                        }}
                      />
                    </div>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      style={{
                        marginTop: "1rem",
                        textTransform: "none",
                        color: "#4B4B4D",
                      }}
                    >
                      Continue
                    </Button>
                  </form>
                </>
              )}
            </div>
          )}
          {stage === 4 &&
            (capcha ? (
              <div
                className="listContainer"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: isMobile ? "1rem" : "",
                }}
              >
                {!captchaRef && (
                  <ThemeProvider theme={theme}>
                    <CircularProgress color="primary" />
                  </ThemeProvider>
                )}

                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                  onChange={handleRecaptchaChange}
                />
              </div>
            ) : (
              <div className="listContainer" style={{}}>
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#ccc",
                    textAlign: "center",
                  }}
                  variant="h6"
                  className="typography"
                >
                  Kindly confirm your reservation details:
                </Typography>
                <Divider
                  color="#E0BF9E"
                  sx={{ marginBottom: "1rem", marginTop: "1rem" }}
                />

                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                    textAlign: "left",
                  }}
                  variant="h6"
                  className="typography"
                >
                  <Person
                    sx={{
                      transform: "translateY(0.25rem)",
                      marginRight: "0.5rem",
                    }}
                  />
                  Name:{" "}
                  <span style={{ fontWeight: "300" }}>{name ? name : ""}</span>
                </Typography>
                <Divider
                  color="#E0BF9E"
                  sx={{ marginBottom: "1rem", marginTop: "1rem" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                    textAlign: "left",
                  }}
                  variant="h6"
                  className="typography"
                >
                  <Call
                    sx={{
                      transform: "translateY(0.25rem)",
                      marginRight: "0.5rem",
                    }}
                  />
                  Phone:{" "}
                  <span style={{ fontWeight: "300" }}>
                    {phone
                      ? phoneCode +
                        (phoneCode === "+20" && phone.slice(0, 1) === "0"
                          ? phone.slice(1, phone.length)
                          : phone)
                      : ""}
                  </span>
                </Typography>
                <Divider
                  color="#E0BF9E"
                  sx={{ marginBottom: "1rem", marginTop: "1rem" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                    textAlign: "left",
                  }}
                  variant="h6"
                  className="typography"
                >
                  <Email
                    sx={{
                      transform: "translateY(0.25rem)",
                      marginRight: "0.5rem",
                    }}
                  />{" "}
                  Email:{" "}
                  <span style={{ fontWeight: "300" }}>
                    {email ? email : ""}
                  </span>
                </Typography>
                <Divider
                  color="#E0BF9E"
                  sx={{ marginBottom: "1rem", marginTop: "1rem" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                    textAlign: "left",
                  }}
                  variant="h6"
                  className="typography"
                >
                  <CalendarIcon
                    sx={{
                      transform: "translateY(0.25rem)",
                      marginRight: "0.5rem",
                    }}
                  />{" "}
                  Date:{" "}
                  <span style={{ fontWeight: "300" }}>
                    {date ? new Date(date).toLocaleDateString("en-gb") : ""}
                  </span>
                </Typography>
                <Divider
                  color="#E0BF9E"
                  sx={{ marginBottom: "1rem", marginTop: "1rem" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                    textAlign: "left",
                  }}
                  variant="h6"
                  className="typography"
                >
                  <ClockIcon
                    sx={{
                      transform: "translateY(0.25rem)",
                      marginRight: "0.5rem",
                    }}
                  />{" "}
                  Time Slot:{" "}
                  <span style={{ fontWeight: "300" }}>{slot ? slot : ""}</span>
                </Typography>
                <Divider
                  color="#E0BF9E"
                  sx={{ marginBottom: "1rem", marginTop: "1rem" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#fff",
                    textAlign: "left",
                    marginBottom: isMobile ? "1rem" : 0,
                  }}
                  variant="h6"
                  className="typography"
                >
                  <People
                    sx={{
                      transform: "translateY(0.25rem)",
                      marginRight: "0.5rem",
                    }}
                  />{" "}
                  Number of guests:{" "}
                  <span style={{ fontWeight: "300" }}>
                    {count ? count : ""}
                  </span>
                </Typography>
              </div>
            ))}
          {!success && stage !== 3 && (
            <Button
              variant="contained"
              onClick={handleContinue}
              disabled={loading}
              sx={{
                textTransform: "none",
                color: "#4B4B4D",
                "&.Mui-disabled": {
                  background: "#bbb",
                  color: "#555",
                },
                marginTop: "0.5rem",
              }}
            >
              {!loading ? (stage === 4 ? "Confirm" : "Continue") : "Loading..."}
            </Button>
          )}
          {stage === 3 && otpVisible && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-evenly",
                height: "15%",
                zIndex: 100,
              }}
            >
              <Button
                variant="contained"
                onClick={handleContinue}
                disabled={loading || !otp || otp.length < 4}
                sx={{
                  color: "#4B4B4D",
                  textTransform: "none",
                  width: "100px",
                  "&.Mui-disabled": {
                    background: "#bbb",
                    color: "#555",
                  },
                }}
              >
                {!loading
                  ? stage === 4
                    ? "Confirm"
                    : "Continue"
                  : "Loading..."}
              </Button>
              <Button
                variant="contained"
                onClick={() => setOtpVisible(false)}
                sx={{
                  margin: 0,
                  width: "100px",
                  backgroundColor: "#888",

                  "&.Mui-disabled": {
                    background: "#bbb",
                    color: "#555",
                  },
                }}
              >
                Cancel
              </Button>
            </div>
          )}
          {stage === 2 && errors?.pax && alertt && (
            <div
              style={{
                minHeight: 50,
                marginTop: isMobile ? "1rem" : "0rem",
                zIndex: 100,
                maxWidth: isMobile ? "90%" : "unset",
              }}
            >
              {renderErrorAlert(errors?.pax, "error")}
            </div>
          )}
          {
            <div
              style={{
                minHeight: 50,
                marginTop: isMobile ? "1rem" : "0rem",
                zIndex: 100,
                maxWidth: isMobile ? "90%" : "unset",
              }}
            >
              {alertt &&
                (renderErrorAlert(errors?.name, "error") ||
                  renderErrorAlert(errors?.email, "error") ||
                  renderErrorAlert(errors?.phone, "error") ||
                  renderErrorAlert(errors?.date, "error") ||
                  renderErrorAlert(errors?.slot, "error") ||
                  renderErrorAlert(errors?.otp, "error") ||
                  renderErrorAlert(errors?.seat, "error") ||
                  renderErrorAlert(errors?.area, "error") ||
                  renderErrorAlert(errors?.operating, "error") ||
                  null)}
            </div>
          }
          <Typography
            sx={{
              fontSize: 14,
              color: "#fff",
              textAlign: "center",
              fontWeight: "300",
              position: "fixed",
              bottom: "1rem",
              // marginTop: isMobile ? 3 : 0,
              // transform: stage === 4 ? "translateY(-1rem)" : "unset",
            }}
            variant="h6"
            className="typography"
          >
            Powered by{" "}
            <a
              style={{ color: "#fff" }}
              href="https://anyware.software"
              target="_blank"
              rel="noreferrer"
            >
              Anyware.Software
            </a>
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ReservationPage;
