// import { LambdaNames } from "../constants/Enums";
// import { invokeLambda } from "../helpers/utils";
import axios from "axios";
import { EndPoints } from "../constants/constants";

async function sendEmailWithOTP({ userEmail }) {
  try {
    const operationId = 99;
    const emailAttributes = {
      userEmail,
      sourceMail: "info@anyware.software",
    };
    const requestBody = {
      operationId,
      queryStringParameters: emailAttributes,
    };
    // console.log({requestBody});

    // let response = await invokeLambda(
    //   LambdaNames.sendNotifications,
    //   requestBody
    // );

    const response = await axios.post(EndPoints.sendNotifications, requestBody);
    return response.data.otpCode;
  } catch (error) {
    console.error("Error sending email :", error);
    throw error;
  }
}

export default sendEmailWithOTP;
